import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
	// ExportOutlined,
	PhoneOutlined,
	InstagramOutlined
  } from '@ant-design/icons';
import styled from 'styled-components';
import { Layout, Row, Col, Menu } from 'antd';

const { Footer, Content, Header } = Layout;

const LayoutContent = ({
	children,
	pageName,
	showPageName = false,
	sidebarCurrentKey = 'home',
}) => {

	const IconHover = styled.p`
	color: #fff;
	font-size: 20px;
	padding-left: 15px;
	&:hover {
		color: #004f9e;
	}
`



	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Layout>
				<Header style={{ background: '#fff', textAlign: 'left', height: 250, padding: 0, lineHeight: 0 }}>
					<Row  align="middle" style={{ background: '#0084ff', paddingLeft: 80, color: "#fff", height: 40 }}>
						<Col>
							<b><span>Contato <PhoneOutlined /> (21) 2280-0648</span></b>
							<IconHover as ="a" href="https://www.instagram.com/institutorelvas/" target="_blank"> <InstagramOutlined /> </IconHover>
						</Col>
						{/* <Col xs={8} xl={4} style={{paddingRight: 10}}>
						<Link to="/login" style={{ color: "#fff"}}><b>Área Acadêmica <ExportOutlined /></b></Link>
						</Col> */}
					</Row>
					<Row justify="space-between" align="middle">
						<Col xs={12} xl={2} style={{ textAlign: 'left', paddingLeft: 50, paddingTop: 20}}>
							<Link to='/'>
								<img 
									alt="example"
									src="./assets/logoRelvas.png"
								/>
							</Link>
						</Col>
						<Col xs={6} xl={12} style={{ textAlign: 'left', paddingTop: 20 }}>
							<Menu 
							mode="horizontal"
							style={{ border: 0}}
							defaultSelectedKeys={[sidebarCurrentKey]}>
								<Menu.Item key="home">
									<Link to='/'>Início</Link>
								</Menu.Item>
								{/* <Menu.Item key="app">
									<Link to='/'>Nada</Link>
								</Menu.Item> */}
								<Menu.Item key="galeria">
									<Link to='/galeria-de-fotos'>Galeria de Fotos</Link>
								</Menu.Item>
								<Menu.Item key="contato">
									<Link to='/fale-conosco'>Contato</Link>
								</Menu.Item>
							</Menu>
						</Col>
					</Row>
					{showPageName &&
						<Row justify="start" align="middle" style={{ background: '#0084ff', color: '#fff', height: 70, paddingTop: 10}}>
							<Col style={{ paddingLeft: 80, fontSize: 25 }}>
								<b><span>{pageName}</span></b>
							</Col>
						</Row>
					}
				</Header>
				<Content className="content">{children}</Content>
				<Footer
					style={{
						position: 'relative',
						bottom: 0,
						left: 0,
						width: '100%',
						textAlign: 'center',
						background: '#0084ff',
						color: '#fff',
					}}
				>
					<span>Instituto Relvas &copy;Copyright 2020. Todos direitos reservados.</span>
				</Footer>
			</Layout>
		</Layout>
	);
};

LayoutContent.propTypes = {
	children: PropTypes.node.isRequired,
	sidebar: PropTypes.element,
};

export default withRouter(LayoutContent);
