import React from 'react';
import './App.css';

import { Route, BrowserRouter as Router } from 'react-router-dom';

import Home from './pages/Home'; 
import Contact from "./pages/Contact"
import NewStudents from './pages/NewStudents';
import Gallery from './pages/Gallery';

function App() {
	return (
		<div>
			<Router>
				<Route exact path="/" component={Home}></Route>
				<Route exact path="/fale-conosco" component={Contact}></Route>
				<Route exact path="/matriculas-abertas" component={NewStudents}></Route>
				<Route exact path="/galeria-de-fotos" component={Gallery}></Route>
			</Router>
		</div>
	);
}

export default App;  