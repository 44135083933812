import React from 'react'; // react hooks
import LayoutContent from '../components/Layout';
import { Row, Col } from 'antd';
import ContactForm from '../components/ContactForm'
import styled from 'styled-components';
import MapContainer from '../components/Map'
import {
    PhoneOutlined,
    MailOutlined,
    ClockCircleOutlined
  } from '@ant-design/icons';

const Contact = () => {
// #271770  004f9e
    const H1 = styled.p`
        color: #271770;
        font-size: 24px;
        margin: 0 0 40px;
        font-weight: bold;
        font-family: 'uni-sans-bold';
    `
    const H2 = styled.p`
        color: #0b3a84;
        font-size: 38px;
        margin: 0 0 40px;
        font-weight: 700;
        font-family: 'uni-sans-bold';
    `

    const P = styled.p`
        margin: 0;
        text-align: left;
        font-weight: 700;
        font-family: 'uni-sans-book';
        color: #313131;
        font-size: 16px;
    `

    const Sub = styled.p`
        margin: 0 0 10px;
        text-align: left;
        font-weight: 700;
        font-family: 'uni-sans-bold';
        color: #313131;
        font-size: 15px;
    `

    return(
        <LayoutContent showPageName = {true} pageName={`Contato`} sidebarCurrentKey='contato'>
            <Row justify="center" style={{ background: '#f0f2f5', padding: '60px 60px 100px', marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={24} lg={24} xl={7} xxl={6}
                style={{ 
                    background: '#fff', 
                    borderStyle: 'outset', 
                    border: '8px solid white', 
                    borderRadius: '4px',
                    padding: '40px 60px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    marginBottom: 20
                }}>
                    <H1>Localização e Formas de Contato</H1>
                    <P>Rua Teixeira Franco, 3, 13 - Ramos</P>
                    <P>CEP: 21060-130 - Rio de Janeiro - RJ</P>
                    <Sub><PhoneOutlined style={{ paddingTop: 40, paddingRight: 10}}/>(21) 2280-0648</Sub>
                    <Sub><PhoneOutlined style={{ paddingRight: 10}}/>(21) 2573-7132</Sub>
                    <Sub><ClockCircleOutlined style={{ paddingRight: 10}}/>07:00 às 16:00</Sub>
                    <Sub><MailOutlined style={{ paddingRight: 10}}/>secretariarelvas@gmail.com</Sub>
                    <div style={{ margin: '0 0 88px'}}></div>
                </Col>
                <div style={{ padding: 15 }}></div>
                <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={14}
                style={{
                    background: '#f0f2f5', 
                    borderStyle: 'outset',
                    border: '8px solid #f0f2f5', 
                    borderRadius: '4px',
                    padding: '40px 60px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                    height: '34em',
                }}>
                    <MapContainer></MapContainer>
                </Col>
            </Row>
            <Row justify="center"
            style={{ 
                background: '#fff',
                padding: '100px 0',
                paddingRight: '15px',
                paddingLeft: '15px',
                marginRight: 'auto',
                marginLeft: 'auto'
            }}>
                <Col span={24}>
                    <H2>Fale Conosco</H2>
                </Col>
                <Col span={6}>
                    <ContactForm></ContactForm>
                </Col>
            </Row>
        </LayoutContent>

    );

};

export default Contact;