export const items = [

    {
        key: '1',
        content: './assets/matriculasAbertasBig.png',
        medium: './assets/matriculasAbertas1680.png',
        halfMedium: './assets/matriculasAbertas1440.png',
        small: './assets/matriculasAbertas1368.png',
        small1X: './assets/matriculasAbertas1280.png',
        small2X: './assets/matriculasAbertas1024.png',
        small3X: './assets/matriculasAbertas800.png',
        phone: './assets/matriculasAbertasMobile.png',
        imageLink: true,
    },
    {
        key: '2',
        content: './assets/topo11Big.png',
        medium: './assets/topo11_1680.png',
        halfMedium: './assets/topo11_1440.png',
        small: './assets/topo11_1368.png',
        small1X: './assets/topo11_1280.png',
        small2X: './assets/topo11_1024.png',
        small3X: './assets/topo11_800.png',
        phone: './assets/topo11_mobile.png',
        imageLink: false,
    },
    {
        key: '3',
        content: './assets/topo22Big.png',
        medium: './assets/topo22_1680.png',
        halfMedium: './assets/topo22_1440.png',
        small: './assets/topo22_1368.png',
        small1X: './assets/topo22_1280.png',
        small2X: './assets/topo22_1024.png',
        small3X: './assets/topo22_800.png',
        phone: './assets/topo22_mobile.png',
        imageLink: false,
    },
    {
        key: '4',
        content: './assets/topo33Big.png',
        medium: './assets/topo33_1680.png',
        halfMedium: './assets/topo33_1440.png',
        small: './assets/topo33_1368.png',
        small1X: './assets/topo33_1280.png',
        small2X: './assets/topo33_1024.png',
        small3X: './assets/topo33_800.png',
        phone: './assets/topo33_mobile.png',
        imageLink: false,
    },
    {
        key: '5',
        content: './assets/topo44Big.png',
        medium: './assets/topo44_1680.png',
        halfMedium: './assets/topo44_1440.png',
        small: './assets/topo44_1368.png',
        small1X: './assets/topo44_1280.png',
        small2X: './assets/topo44_1024.png',
        small3X: './assets/topo44_800.png',
        phone: './assets/topo44_mobile.png',
        imageLink: false,
    },
    {
        key: '6',
        content: './assets/topo55Big.png',
        medium: './assets/topo55_1680.png',
        halfMedium: './assets/topo55_1440.png',
        small: './assets/topo55_1368.png',
        small1X: './assets/topo55_1280.png',
        small2X: './assets/topo55_1024.png',
        small3X: './assets/topo55_800.png',
        phone: './assets/topo55_mobile.png',
        imageLink: false,
    },

];