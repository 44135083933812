import React from 'react'; // react hooks
import LayoutContent from '../components/Layout';
import PhotosGallery from '../components/PhotosGallery'

const Gallery = () => {

    return(
        <LayoutContent showPageName={true} pageName={`Galeria de Fotos`} sidebarCurrentKey='galeria'>
            <PhotosGallery></PhotosGallery>
        </LayoutContent>
    )
}

export default Gallery;