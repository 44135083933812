import React, { useState } from 'react';
import { Form, Input, Button, Select } from 'antd';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
const { Option } = Select;

const { TextArea } = Input;

const ContactForm = ({
	newStudentsPage = false,
}) => {
	const [buttonText, setButtonText] = useState('Enviar');
	const [newStudentsButtonText, setNewStudentsButtonText] = useState('Desejo receber mais informações');
	const [sending, setSending] = useState(false);
	const [sendingDone, setSendingDone] = useState(false);

	const [name, setName] = useState('');
	const [candidateName, setCandidateName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [optionSelected, setOptionSelected] = useState('');

	const handleSelect = e =>{
		setOptionSelected(e);
	}

	const handleSubmit = e => {
		e.preventDefault();
		setButtonText('Enviando...');
		setNewStudentsButtonText('Enviando...');
		setSending(true);

		const data = {
			name,
			candidateName,
			email,
			phone,
			message,
			optionSelected,
			newStudentsPage
		};

		fetch(process.env.REACT_APP_API_CONTACT_ENDPOINT, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		})
			.then(res => {
				if (res.status === 200) {
					setButtonText('Mensagem enviada com sucesso!');
					setNewStudentsButtonText('Mensagem enviada com sucesso!')
				}
			})
			.catch(e => {
				console.log(e);
				setButtonText(
					'Falha ao enviar a mensagem, entre em contato mais tarde.'
				);
				setNewStudentsButtonText('Falha ao enviar a mensagem, entre em contato mais tarde.');
			})
			.finally(() => {
				setSending(false);
				setSendingDone(true);
			});
	};

	return (
		<Form
			labelCol={{ xs: { span: 24 }, sm: { span: 12 } }}
			wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
			layout="vertical"
		>
			{newStudentsPage
			? 
				<Form.Item required label="Nome do Responsável">
					<Input 
					required 
					onChange={e => setName(e.target.value)} />
				</Form.Item>
			:
				<Form.Item required label="Nome">
					<Input
					required 
					onChange={e => setName(e.target.value)} />
				</Form.Item>
			}
			{newStudentsPage &&
				<Form.Item required label="Nome do Candidato">
					<Input
					required
					onChange={e => setCandidateName(e.target.value)} />
				</Form.Item>
			}
			<Form.Item required label="Email">
				<MaskedInput
					mask={emailMask}
					className="ant-input"
					onChange={e => setEmail(e.target.value)}
				/>
			</Form.Item>
			<Form.Item required label="Telefone">
				<MaskedInput
					mask={[
						'(',
						/[1-9]/,
						/\d/,
						')',
						' ',
						/\d/,
						/\d/,
						/\d/,
						/\d/,
						'-',
						/\d/,
						/\d/,
						/\d/,
						/\d/,
						/\d?/,
					]}
					guide={false}
					className="ant-input"
					required
					onChange={e => setPhone(e.target.value)}
				/>
			</Form.Item>
			{newStudentsPage 
			?
				<Form.Item
					required
					label="Escolha a série de interesse"
				>
					<Select
					placeholder="Selecione uma opção"
					onChange={handleSelect}
					>
						<Option value="PreEscola">Pré-Escola</Option>
						<Option value="1anoEF">1 ano do Ensino Fundamental</Option>
						<Option value="2anoEF">2 ano do Ensino Fundamental</Option>
						<Option value="3anoEF">3 ano do Ensino Fundamental</Option>
						<Option value="4anoEF">4 ano do Ensino Fundamental</Option>
						<Option value="5anoEF">5 ano do Ensino Fundamental</Option>
						<Option value="6anoEF">6 ano do Ensino Fundamental</Option>
						<Option value="7anoEF">7 ano do Ensino Fundamental</Option>
						<Option value="8anoEF">8 ano do Ensino Fundamental</Option>
						<Option value="9anoEF">9 ano do Ensino Fundamental</Option>
						<Option value="1anoEM">1 ano do Ensino Médio</Option>
						<Option value="2anoEM">2 ano do Ensino Médio</Option>
						<Option value="3anoEM">3 ano do Ensino Médio</Option>
					</Select>
	  			</Form.Item>
			:
				<Form.Item required label="Mensagem">
			   		<TextArea rows={4} onChange={e => setMessage(e.target.value)} />
				</Form.Item>
			}
				
			<Form.Item wrapperCol={{ xs: 24, sm: 24 }}>
				{newStudentsPage ?
				<Button
					type="primary"
					htmlType="submit"
					size="large"
					loading={sending && !sendingDone}
                    disabled={!sending && sendingDone}
                    block='true'
					// shape='round'
					onClick={handleSubmit}
				>
					{newStudentsButtonText}
				</Button>
				:
				<Button
					type="primary"
					htmlType="submit"
					size="large"
					loading={sending && !sendingDone}
                    disabled={!sending && sendingDone}
                    block='true'
					// shape='round'
					onClick={handleSubmit}
				>
					{buttonText}
				</Button>
				}
			</Form.Item>
		</Form>
	);
};

export default ContactForm;
