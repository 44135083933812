import React from 'react'; // react hooks
import LayoutContent from '../components/Layout';
import { Row, Col } from 'antd';
import ContactForm from '../components/ContactForm'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NewStudents = () => {

    const H1 = styled.p`
        color: #0b3a84;
        font-size: 30px;
        margin: 0 0 40px;
        font-weight: bold;
        font-family: 'uni-sans-bold';
        text-align: left;
    `

    const H2 = styled.p`
    color: #0b3a84;
    font-size: 38px;
    margin: 0 0 40px;
    font-weight: 700;
    font-family: 'uni-sans-bold';
    text-align: left;
`

const H3 = styled.p`
    color: #0b3a84;
    font-size: 60px;
    margin: 0 0 40px;
    font-weight: 700;
    font-family: 'uni-sans-bold';
    text-align: center;
`

    const P = styled.p`
        margin: 0 0 40px;
        font-weight: 700;
        font-family: 'uni-sans-book';
        color: #313131;
        font-size: 16px;
        text-align: left;
    `

    return(

        <LayoutContent showPageName = {true} pageName={`Matrículas Abertas - Da Educação Infantil ao Ensino Médio`} sidebarCurrentKey='home'>
            <Row justify="end" align="middle" style={{
                background: '#f0f2f5',
                padding: '60px 60px',
                marginRight: 'auto',
                marginLeft: 'auto',
                borderStyle: 'outset', 
                borderRadius: '4px',
                border: '4px solid #f0f2f5', 
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',      

                }}>
                <Col span={12}>
                    <H3>Matrículas Abertas 2022</H3>
                </Col>
                <Col span={12} style={{ 
                    background: '#fff', 
                    borderStyle: 'outset', 
                    border: '8px solid white', 
                    borderRadius: '4px',
                    padding: '40px 60px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',      
                }}>
                    <Link to='/'>
								<img 
									alt="example"
									src="./assets/logoRelvas.png"
								/>
							</Link>
                    <ContactForm newStudentsPage={true}></ContactForm>
                </Col>
            </Row>
            <Row style={{ 
                background: '#fff', 
                borderStyle: 'outset', 
                border: '8px solid white', 
                borderRadius: '4px', padding: '40px 60px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
                }}>
                <Col span={24}>
                    <H2>Matrículas Abertas, garanta já o futuro do seu filho!</H2>
                    <H1>Documentos necessários</H1>
                    <P>Certidão de Nascimento(Xerox legível)</P>
                    <P>Carteira de Identidade</P>
                    <P>CPF</P>
                    <P>Declaração Escolar de Procedência(Indicando o ano que o aluno(a) está cursando, deverá constar a assinatura do diretor e respectivo registro)</P>
                    <P>3 fotos(3x4)</P>
                    <P>Xerox da carteira de vacinação(Jardim e 1º ano)</P>
                    <P>Grade curricular para os candidatos de 2ª e 3ª serie do ensino médio</P>
                </Col>
            </Row>
        </LayoutContent>

    );
};

export default NewStudents;