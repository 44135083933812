import React from 'react'; // react hooks
import LayoutContent from '../components/Layout';
import { Row, Col, Carousel } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { items } from "../utils/items"


const Home = () => {

	const H1 = styled.p`
		color: #271770;
		font-size: 24px;
		margin: 0 0 40px;
		font-weight: bold;
		font-family: 'uni-sans-bold';
`
	const H2 = styled.p`
		color: #271770;
		font-size: 38px;
		margin: 0 0 40px;
		font-weight: 700;
		font-family: 'uni-sans-bold';
	`

	const P = styled.p`
		text-align: justify;
		font-weight: 500;
		font-family: 'uni-sans-book';
		color: #313131;
		font-size: 20px;
	`

	return (
		<LayoutContent>
			<div 
			style={{
				background: '#f0f2f5', 
				boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
				}}>
			<Row justify="center" align="middle" >
				<Col span={24} style={{ width: "100%", padding: '64px 64px 0px' }}>
					<Carousel autoplay effect="fade" dots={false}>
								{items.map(item => {
									return (
										<div>
											<div>
												{item.imageLink
													? 
														<Link to="/matriculas-abertas">
															<img
														alt="example"
														src={`${item.content}`}
														srcSet={`${item.content} 1920w, ${item.medium} 1680w, ${item.halfMedium} 1440w, ${item.small} 1368w, ${item.small1X} 1280w, ${item.small2X} 1024w`}
														/>
														</Link>
													:
														<img
														alt="example"
														src={`${item.content}`}
														srcSet={`${item.content} 1920w, ${item.medium} 1680w, ${item.halfMedium} 1440w, ${item.small} 1368w, ${item.small1X} 1280w, ${item.small2X} 1024w`}
														/>
												}	
											</div>
										</div>
									)
								})}
					</Carousel>
				</Col>
			</Row>
			<div style={{
				background: '#fff', 
				padding: '40px 60px',
			}}>
			<Row>
				<Col span={24}>
					<H2>Instituto Relvas</H2>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<H1>64 anos na Educação de Qualidade</H1>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<P>Fundado em 1957, há 64 anos na Educação de Qualidade, formando cidadãos!</P>

					<P>Situado na zona da Leopoldina, em Ramos, no Rio de Janeiro, o Instituto Relvas proporciona aos seus alunos uma formação educacional que vai da pré-escola ao ensino médio.</P>

					<P>Nossa filosofia é desenvolver no aluno, uma atitude crítica através de atividades que estimulem a iniciativa, a solidariedade e a ética.</P>

					<P>Nossos alunos são orientados para que realizem projetos com o objetivo de levar o ensino, a cultura e a informação.</P>

					<P>Nosso ideal é proporcionar aos alunos um ambiente de bem-estar, amizade e diálogo que ajude-os a tornar-se sujeito de seu próprio desenvolvimento.</P>

					<P>Mantendo a tradição, o Instituto Relvas proporciona ao educando uma formação integral e uma orientação eficiente obedecendo às novas tendências educacionais.</P>
					<P> </P>
					{/* <P>
						Fundado em 1957, há 64 anos na Educação de Qualidade, formando cidadãos! Situado na zona da Leopoldina,
						em Ramos, no Rio de Janeiro, o Instituto Relvas proporciona aos seus alunos uma formação educacional que vai da pré-escola ao ensino médio.
						 Nossa filosofia é desenvolver no aluno, uma atitude crítica através de atividades que estimulem a iniciativa, a solidariedade e a ética.
						 Nossos alunos são orientados para que realizem projetos com o objetivo de levar o ensino, a cultura e a informação.
						 Nosso ideal é proporcionar aos alunos um ambiente de bem-estar, amizade e diálogo que ajude-os a tornar-se sujeito de seu próprio desenvolvimento.
						 Mantendo a tradição, o Instituto Relvas proporciona ao educando uma formação integral e uma orientação eficiente obedecendo às novas tendências educacionais.
					</P> */}
				</Col>
			</Row>
			</div>
			</div>
		</LayoutContent>
	);
};

export default Home;
