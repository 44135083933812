import React, { Component } from 'react'; // react hooks
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const containerStyle = {
    position: 'inherit',  
    width: '100%',
    height: '100%'
  }

export class MapContainer extends Component {

    render() {
      return (
  
        <Map
            containerStyle={containerStyle}
            google={this.props.google}
            zoom={19}
            initialCenter={{ lat: -22.854206449305515, lng: -43.263870739767526 }}
        >
          <Marker name={'Instituto Relvas'} position={{ lat: -22.854206449305515, lng: -43.263870739767526  }} />
        </Map>
  
      );
    }
  }

export default GoogleApiWrapper(
    () => ({
      apiKey: process.env.REACT_APP_API_MAP_ENDPOINT,
    }
  ))(MapContainer)

